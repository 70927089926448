import React, { Component } from 'react';
import styled from 'styled-components/macro';
import ButtonsPanel from './ButtonsPanel';
import Intro from './Intro.js';
import Page from './Page.js';
import Profile from './Profile.js';
import About from './Journey.js';
import Skills from './Skills.js';
import Contact from './Contact.js';
import Background from './Background.js';

const PAGE_INTRO = "intro";
const PAGE_PROFILE = "profile";
const PAGE_JOURNEY = "journey";
const PAGE_SKILLS = "skills";
const PAGE_CONTACT = "contact";

// Enable disable debug
const DEBUG = (process.env.NODE_ENV == "development") ? true : false;
const DEBUG_PAGE = PAGE_INTRO;


const StyledAppContent = styled.div`
  width: 100%;
  height: 100%;
`;

const StyledApp = styled.div`
  width: 100%;
  height: 100%;
`;

export class App extends Component {
  constructor() {
    super();

    this.state = {
      onTransition: false,
      page: DEBUG ? DEBUG_PAGE : PAGE_INTRO,
      mouseX: 0,
      mouseY: 0
    }

    this.handleTransition = this.handleTransition.bind(this);
    this.handleMouse = this.handleMouse.bind(this);
  }

  componentDidMount() {
    if (this.props.match.params.page) {
      var s = this.props.match.params.page;
      if (s == PAGE_INTRO || s == PAGE_PROFILE || s == PAGE_JOURNEY || s == PAGE_SKILLS || s == PAGE_CONTACT) {
        this.setState({page: s});
      }
      else window.location = "/";
    }
  }

  handleTransition(page, running) {
    let onTransition = this.state.page === PAGE_INTRO ? false : running; 
    this.setState({
      page: page,
      onTransition: onTransition
    });
  }

  handleMouse(e) {
    this.setState({
      mouseX: e.clientX,
      mouseY: e.clientY
    });
  }

  render() {
    var render;

    if (this.state.page === PAGE_INTRO) {
      render = (
        <>
          <ButtonsPanel layout="0" handleTransition={this.handleTransition} key="0"/>
          <Intro />
        </>
      );
    }
    else if (this.state.page === PAGE_PROFILE) {
      render = (
        <>
          <ButtonsPanel layout="1" active="profile" handleTransition={this.handleTransition} key="0"/>
          <Page active="profile" row="0" column="0" transition={this.state.onTransition} handleTransition={this.handleTransition}><Profile active="profile" /></Page>
          <Page active="profile" row="0" column="1" transition={this.state.onTransition} handleTransition={this.handleTransition}><About active="profile" /></Page>
          <Page active="profile" row="1" column="0" transition={this.state.onTransition} handleTransition={this.handleTransition}><Skills active="profile" /></Page>
          <Page active="profile" row="1" column="1" transition={this.state.onTransition} handleTransition={this.handleTransition}><Contact active="profile" /></Page>
        </>
      );
    }
    else if (this.state.page === PAGE_JOURNEY) {
      render = (
        <>
          <ButtonsPanel layout="1" active="journey" handleTransition={this.handleTransition} key="0"/>
          <Page active="journey" row="0" column="-1" transition={this.state.onTransition} handleTransition={this.handleTransition}><Profile active="journey" /></Page>
          <Page active="journey" row="0" column="0" transition={this.state.onTransition} handleTransition={this.handleTransition}><About active="journey" /></Page>
          <Page active="journey" row="1" column="-1" transition={this.state.onTransition} handleTransition={this.handleTransition}><Skills active="journey" /></Page>
          <Page active="journey" row="1" column="0" transition={this.state.onTransition} handleTransition={this.handleTransition}><Contact active="journey" /></Page>
        </>
      );
    }
    else if (this.state.page === PAGE_SKILLS) {
      render = (
        <>
          <ButtonsPanel layout="1" active="skills" handleTransition={this.handleTransition} key="0"/>
          <Page active="skills" row="-1" column="0" transition={this.state.onTransition} handleTransition={this.handleTransition}><Profile active="skills" /></Page>
          <Page active="skills" row="-1" column="1" transition={this.state.onTransition} handleTransition={this.handleTransition}><About active="skills" /></Page>
          <Page active="skills" row="0" column="0" transition={this.state.onTransition} handleTransition={this.handleTransition}><Skills active="skills" /></Page>
          <Page active="skills" row="0" column="1" transition={this.state.onTransition} handleTransition={this.handleTransition}><Contact active="skills" /></Page>
        </>
      );
    } 
    else if (this.state.page === PAGE_CONTACT) {
      render = (
        <>
          <ButtonsPanel layout="1" active="contact" handleTransition={this.handleTransition} key="0"/>
          <Page active="contact" row="-1" column="-1" transition={this.state.onTransition} handleTransition={this.handleTransition}><Profile active="contact" /></Page>
          <Page active="contact" row="-1" column="0" transition={this.state.onTransition} handleTransition={this.handleTransition}><About active="contact" /></Page>
          <Page active="contact" row="0" column="-1" transition={this.state.onTransition} handleTransition={this.handleTransition}><Skills active="contact" /></Page>
          <Page active="contact" row="0" column="0" transition={this.state.onTransition} handleTransition={this.handleTransition}><Contact active="contact" /></Page>
        </>
      );
    } 



    return (
      <StyledApp onMouseMove={this.handleMouse}>
          <StyledAppContent>
            {render}
          </StyledAppContent>
          <Background mouseX={this.state.mouseX} mouseY={this.state.mouseY}/>
      </StyledApp>
    );
  }
}

export default App;
