import React, { Component } from 'react'
import styled from 'styled-components/macro'
import * as cs from './commonStyles.js'
import content from './content/Profile.yaml'
import theme from './content/Theme.yaml'


const StyledProfile = styled(cs.StyledSection)`
  background-color: ${theme.colors.background1};
  color: ${theme.colors.color5};
`;

const StyledHeader = styled(cs.StyledHeader)`
    color: ${theme.colors.color1};
`;

const StyledContent = styled.div`
  display: flex;
  //border: 1px solid black;
  width: 100%;
  min-width: 100%;
  max-width: 100%;

  line-height: 2em;
  font-size: 0.9em;
  //align-items: center;
  padding-left: 10px;

  @media(max-width: 768px) {
    flex-flow: column;
    align-items: center;
  }
`;

const StyledPhoto = styled.img`
  margin-right: 60px;
  @media(max-width: 768px) {
    margin-right: 0;
    margin-bottom: 20px;
  }
`;

const StyledInfo = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 0px;
  padding-bottom: 50px;
  flex-basis: 500px;
  flex-shrink: 1;

  .header {
    margin-bottom: 35px;
    font-size: 1.7rem;
    font-weight: normal;
    color: ${theme.colors.color4};
    letter-spacing: 0.2em;
  }
`;

const StyledDetails = styled.div`
  margin-right: 80px;

  .title {
    font-weight: bold;
    font-size: 1.1em;
  }

  .description {
    margin-bottom: 0.75em;
    font-size: 1.1em;
    line-height: 1.5em;
  }
`;

const StyledAboutMe = styled.div`
  font-size: 1.1em;
`;

export default class Profile extends Component {
  render() {
    return (
      <StyledProfile>
        {content.test}
        <StyledHeader>Profile</StyledHeader>
        <StyledContent>
          <StyledPhoto src={content.photo.src} width={content.photo.width} height={content.photo.height}></StyledPhoto>
          <StyledInfo>
            <StyledDetails>
              <div className="header">
                Details
              </div>
              <div className="title">
                Name
              </div>
              <div className="description">  
                {content.details.name}
              </div>
              <div className="title">
                Role
              </div>
              <div className="description">    
                {content.details.role}
              </div>
              <div className="title">
                Location
              </div>
              <div className="description">  
                ⚲ {content.details.location}
              </div>
            </StyledDetails>
            <StyledAboutMe>
              <div className="header">About Me</div>
              <div dangerouslySetInnerHTML={{ __html: content.aboutMe }}>
              </div>
            </StyledAboutMe>
          </StyledInfo>
        </StyledContent>
      </StyledProfile>
    )
  }
}
