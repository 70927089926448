import React, { Component } from 'react'
import styled from 'styled-components/macro'
import content from './content/Intro.yaml'
import theme from './content/Theme.yaml'


const StyledIntro = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  background-color: ${theme.colors.background1};
  width: 100%;
  height: 100%;
  transition: background-color 1s;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    background-image: ${props => "url(" + props.image + ")"};
    background-size: cover;
    background-position: center;
    background-color: black;
    width: 100%;
    height: 100%;
    opacity: 0.4;
  }
`;

const StyledTitle1 = styled.h1`
  font-family: 'Big Shoulders Stencil Text';
  font-weight: bold;
  font-size: 2.5em;
  letter-spacing: 0.5em;
  width: fit-content;
  color: black;
  align-self: center;
`;

const StyledTitle2 = styled.h1`
  margin-top: 0.3em;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 1.2em;
  letter-spacing: 0.1em;
  font-weight: normal;
  width: fit-content;
  color: ${theme.colors.color-5};
  align-self: center;
`;

const StyledCredits = styled.div`
  position: absolute;
  right: 15px;
  bottom: 10px;
  font-size: 0.8rem;
  font-family: 'Courier New', Courier, monospace;
  color: ${theme.colors.color5};
`;

export default class Intro extends Component {
  render() {
    return (
      <StyledIntro image="home-background.jpg">
        <StyledTitle1>{content.title}</StyledTitle1>
        <StyledTitle2>{content.subtitle}</StyledTitle2>
        <StyledCredits>Design & coding by Ibon Cano</StyledCredits>
      </StyledIntro>
    )
  }
}
