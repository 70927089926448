import React, { Component } from 'react'
import {Link} from 'react-router-dom';
import styled from 'styled-components/macro'
import theme from './content/Theme.yaml'


const StyledButton = styled(Link)`
  display: flex;
  position: fixed;

  width: ${props => props.width + "px"};
  height: ${props => props.height + "px"};

  background-color: ${props => props.active ? theme.colors.color4 : theme.colors.color1};
  opacity: 0.80;
  text-decoration: none;
  color: ${props => props.active ? theme.colors.color1 : theme.colors.color3};
  transition: height 0.5s, top 0.5s, left 0.5s, background-color 0.25s;
  align-items: center;
  top: ${props => props.coords.top};
  left: ${props => props.coords.left};
  z-index: 1000;

  &:hover {
    cursor: pointer;
    color: ${theme.colors.color1};
    background-color: ${theme.colors.color4};
  }
`;

const StyledButtonName = styled.div`
  font-weight: bold;
  font-size: 0.9em;
  text-align: center;
  flex-grow: 1;
`;

export default class Button extends Component {
  constructor(props) {
    super(props);

    this.handleTransition = this.handleTransition.bind(this);
  }

  handleTransition() {
    if (this.props.active !== this.props.name.toLowerCase()) {
      this.props.handleTransition(this.props.name.toLowerCase(), true);
    }
  }

  render() {
    return (
      <StyledButton width={this.props.width}
                    height={this.props.height}
                    coords={this.props.coords}
                    active={this.props.active === this.props.name.toLowerCase()} 
                    onClick={this.handleTransition}
                    to={this.props.name.toLowerCase()}
      >
        <StyledButtonName>{this.props.name}</StyledButtonName>
      </StyledButton>
    )
  }
}
