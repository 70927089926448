import React, { Component } from 'react'
import styled from 'styled-components/macro'
import * as cs from './commonStyles.js'
import content from './content/Skills.yaml'
import theme from './content/Theme.yaml'


const StyledSkills = styled(cs.StyledSection)`
  background-color: ${theme.colors.background2};
`;

const StyledHeader = styled(cs.StyledHeader)`
    color: ${theme.colors.color3};
`;

const StyledContent = styled.div`
  padding-left: 5px;
`;

const StyledSkillset = styled.div`
  margin-bottom: 60px;

  .name {
    color: ${theme.colors.color4};
    font-size: 1.7rem;
    font-weight: normal;
    letter-spacing: 0.2em;
    margin-bottom: 25px;
  }
`;

const StyledSkill = styled.div`
  color: ${theme.colors.color3};
  font-size: 1em;
  margin-left: 20px;
  margin-bottom: 30px;
  line-height: 2em;
  
  @media(max-width: 768px) {
    margin-left: 0px;
  }

  .title {
    font-weight: normal;
  }

  .title::before {
    content: "▫";
    margin-right: 0.8em;
  }

  .title::after {
    content: "${props => {
      switch(props.stars) {
        case 0:
          return "☆☆☆☆☆";
        case 1:
          return "★☆☆☆☆";
        case 2:
          return "★★☆☆☆";
        case 3:
          return "★★★☆☆";
        case 4:
          return "★★★★☆";
        case 5:
          return "★★★★★";
        default:
          return "";
      }
    }}";
    margin-left: 1.4em;
  };

  .details {
    background-color: none;
    line-height: 1em;
    margin-top: 2px;
    margin-left: 1em;
    width: 450px;

    @media(max-width: 768px) {
      width: auto;
      margin-right: 1em;
    }
  };

  .details > .detail {
    display: inline-block;
    margin: 5px;
    font-size: 0.8em;
    line-height: 1.1em;
    padding: 3px 6px 3px 6px;
    text-align: center;
    border-radius: 12px;
    background-color: ${theme.colors.color6};
    color: ${theme.colors.color5};
  }
`;

export default class Skills extends Component {
  parseContent(content) {
    let skillSets = [];

    for (let ss of content) {
      let skills = [];
      for (let s of ss.skills) {
        let details = [];
        if (s.details) {
          for (let d of s.details) {
            details.push(
              <div className="detail">{d}</div>
            )
          }
        }

        skills.push(
          <StyledSkill stars={s.stars}>
            <div className="title">{s.name}</div>
            <div className="details">
              {details}
            </div>
          </StyledSkill>
        );
      }

      let skillSet = (
        <StyledSkillset>
          <div className="name">{ss.name}</div>
          {skills}
        </StyledSkillset>
      );

      skillSets.push(skillSet);
    }

    return skillSets;
  }

  render() {
    return (
      <StyledSkills>
        <StyledHeader>Skills</StyledHeader>
        <StyledContent>
          {this.parseContent(content)}
        </StyledContent>
      </StyledSkills>
    )
  }
}
