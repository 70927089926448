import React, { Component } from 'react'
import styled from 'styled-components/macro'
import * as cs from './commonStyles.js'
import content from './content/Contact.yaml'
import theme from './content/Theme.yaml'


const StyledContact = styled(cs.StyledSection)`
  background-color: ${theme.colors.background1};
  color: ${theme.colors.color5};
`;

const StyledHeader = styled(cs.StyledHeader)`
  color: ${theme.colors.color1};
`;

const StyledContent = styled.div`
  padding-left: 25px;
`;

const StyledContactEntry = styled.a`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  font-size: 1em;
  color: inherit;
  text-decoration: none;

  &:visited {
    color: inherit;
  }

  .icon {
    background-image: ${props => "url(" + props.icon + ")"};
    background-repeat: no-repeat;
    background-size: contain;
    width: 30px;
    height: 30px;
  }

  .text {
    margin-left: 10px;
  }
`;


export default class Contact extends Component {
  parseContent(content) {
    let contacts = [];
    for (let c of content) {
      contacts.push(
          <StyledContactEntry icon={c.icon} href={c.link} target="_blank">
            <div className="icon"></div><div className="text">{c.text}</div>
          </StyledContactEntry>
      )
    }

    return contacts;
  }

  render() {
    return (
      <StyledContact>
        <StyledHeader>
          Contact
        </StyledHeader>
        <StyledContent>
          {this.parseContent(content)}
        </StyledContent>
      </StyledContact>
    )
  }
}
