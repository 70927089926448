import React, { Component } from 'react'
import * as d3Color from 'd3-color'
import * as d3Force from 'd3-force'
import styled from 'styled-components/macro'
import JSCanvas from './JsCanvas.js'

const StyledCanvas = styled.canvas`
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: -1;
`;

export default class Background extends Component {
  constructor(){
    super();
   
    this.INIT_SIM_DELAY = 900;
    this.NUM_CIRCLES = 350;

    this.state = {
      width: window.innerWidth,
      height: window.innerHeight,
      runningSim: false
    }

    this.canvasRef = React.createRef();

    this.nodes = [];

    // Binds
    this.resizeCanvas = this.resizeCanvas.bind(this);
    this.drawCanvas = this.drawCanvas.bind(this);
    this.simulationStart = this.simulationStart.bind(this);
    this.tick = this.tick.bind(this);

    window.addEventListener("resize", this.resizeCanvas);
  }

  resizeCanvas() {
    this.setState({
      width: window.innerWidth,
      height: window.innerHeight
    });
  }

  componentDidMount() {
    window.addEventListener("resize", this.resizeCanvas);

    this.jsCanvas = new JSCanvas(this.canvasRef.current);
    this.jsCanvas.translate(this.state.width / 2, this.state.height / 2);

    this.nodes.push({
      x: 0,
      y: 0,
      g: 75,
      r: 200,
      c: d3Color.hsl("transparent")
    });

    // Init nodes & circles
    for (let i = 1; i < this.NUM_CIRCLES; i++) {
      let c = d3Color.hsl(0, 0, 0.5, 1);
      c.l = c.l * Math.random();
      c = c.brighter(1);
      this.nodes.push({
        // x: 0,
        // y: 0,
        g: -0.1,
        r: Math.random() * 35 + 5,
        c: c
      });
    }

    // InitForce
    this.simulation = d3Force.forceSimulation(this.nodes);

    setTimeout(this.simulationStart, this.INIT_SIM_DELAY)
  }

  simulationStart() {
    this.setState({runningSim: true});
    this.simulation
    .force("many", d3Force.forceManyBody().strength(d => d.g))
    .force("collide", d3Force.forceCollide().radius(function(d) { return d.r + 1; }).iterations(2));
    this.simulation.velocityDecay(0.22)
    .alphaDecay(0.02).on("tick", this.tick);
  }

  simulationReheat() {
    if (this.state.runningSim) this.simulation.alpha(1).restart();
  }

  componentDidUpdate(prevProps, prevState) {
    if ((this.props.mouseX !== prevProps.mouseX) || (this.props.mouseY !== prevProps.mouseY)) {
      this.nodes[0].fx = this.props.mouseX - this.state.width / 2;
      this.nodes[0].fy = this.props.mouseY - this.state.height / 2;

      if (this.simulation.alpha < this.simulation.alphaMin) {
        this.simulationReheat();
      }
    }

    if ((this.state.width !== prevState.width) || (this.state.height !== prevState.height)) {
      this.jsCanvas.translate(this.state.width / 2, this.state.height / 2);
    }
  }

  drawCanvas() {
    this.jsCanvas.clear();
    for (let i of this.nodes) {
      this.jsCanvas.drawCircle(i.x, i.y, i.r, i.c);
    }
  }

  tick() {
    this.drawCanvas();
  }

  render() {
    return (
      <StyledCanvas ref={this.canvasRef} id="canvas" width={this.state.width} height={this.state.height}></StyledCanvas>
    )
  }
}
