export default class JsCanvas {
  constructor(canvas) {
    this.canvas = canvas;
  }

  width() {
    return this.canvas.width;
  }

  height() {
    return this.canvas.height;
  }

  resetTransform() {
    var ctx = this.canvas.getContext("2d");
    ctx.resetTransform();
  }

  clear() {
    var ctx = this.canvas.getContext("2d");
    ctx.save();
    ctx.resetTransform();
    ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    ctx.restore();
  }

  drawCircle(x, y, radius, color) {
    var ctx = this.canvas.getContext("2d");
    ctx.fillStyle = color;
    ctx.beginPath();
    ctx.arc(x, y, radius, 0, 2 * Math.PI);
    ctx.fill();
  }

  translate(x, y) {
    this.canvas.getContext("2d").translate(x, y);
  }
}