import React, { Component } from 'react'
import styled from 'styled-components/macro'
import * as cs from './commonStyles.js'
import content from './content/Journey.yaml'
import theme from './content/Theme.yaml'


const StyledAbout = styled(cs.StyledSection)`
  color: ${theme.colors.color4};
  background-color: ${theme.colors.background2};
`;

const StyledHeader = styled(cs.StyledHeader)`
  color: ${theme.colors.color3};
`;

const StyledContent = styled.div`
  padding-left: 5px;
`;

const StyledJobList = styled.div`
  margin-bottom: 75px;
  
  .header {
    font-size: 1.7rem;
    letter-spacing: 0.2em;
    margin-bottom: 35px;
    color: ${theme.colors.color4};
  }
`;

const StyledJob = styled.div`
  margin-bottom: 45px;
  margin-left: 20px;
  line-height: 1.5em;

  @media(max-width: 768px) {
    margin-left: 0px;
  }

  .company {
    display: block;
    font-weight: bold;
    font-size: 1.2em;
    margin-bottom: 0px;
    text-decoration: none;
    color: ${theme.colors.color4};

    &:visited {
      color: ${theme.colors.color4};
    }
  }
  
  .location {
    &::before {
      content: "⚲ ";
    }

    font-size: 0.8em;
    color: ${theme.colors.color2};
    margin-left: 4px;
    margin-bottom: 10px;
  }

  .content {
    display: flex;
    align-items: center;

    .logo {
      background-image: ${props => "url(" + props.logo + ")"};
      background-repeat: no-repeat;
      background-size: contain;
      width: 50px;
      min-width: 50px;
      max-width: 50px;
      height: 50px;
      min-height: 50px;
      max-height: 50px;
      margin-right: 20px;
    }

    .info {
      .position {
        color: ${theme.colors.color3};
      }

      .time {
        color: ${theme.colors.color3};
        font-size: 0.9em;
        margin-bottom: 5px;
      }

      .description {
        font-size: 0.9em;
        color: ${theme.colors.color2};
      }
    }
  }
`;

export default class About extends Component {
  parseContent(content) {
    var journey = [];

    for (let c of content) {
      var jobs = [];
      for (let j of c.jobs) {
        jobs.push(
          <StyledJob logo={j.logo}>
              <a className="company" href={j.link} target="_blank">{j.company}</a>
              <div className="location">{j.location}</div>
              <div className="content">
                <a className="logo" href={j.link} target="_blank"></a>
                <div className="info">
                  <div className="position">{j.position}</div>
                  <div className="time">{j.time}</div>
                  <div className="description">{j.description}</div>
                </div>
              </div>
          </StyledJob>
        )
      }

      journey.push(
        <StyledJobList>
          <div className="header">{c.category}</div>
          {jobs}
        </StyledJobList>
      );
    }

    return journey;
  }

  render() {
    return (
      <StyledAbout>
        <StyledHeader>
          Journey
        </StyledHeader>
        <StyledContent>
          {this.parseContent(content)}
        </StyledContent>
      </StyledAbout>
    )
  }
}
