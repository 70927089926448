import React, { Component } from 'react'
import styled from 'styled-components/macro'

const StyledPage = styled.div`
  position: absolute;
  transition: top 0.5s, bottom 0.5s, left 0.5s, right 0.5s;
  min-width: ${props => {
      var v;
      if ((props.row === "0" && props.column === "0") || props.transition) v = "100vw"
      else v = "0";

      return v;
    }
  };
  max-width: ${props => {
      var v;
      if ((props.row === "0" && props.column === "0") || props.transition) v = "100vw"
      else v = "0";

      return v;
    }
  };
  width: ${props => {
      var v;
      if ((props.row === "0" && props.column === "0") || props.transition) v = "100vw"
      else v = "0";

      return v;
    }
  };
  min-height: ${props => {
      var v;
      if ((props.row === "0" && props.column === "0") || props.transition) v = "100vh"
      else v = "0";

      return v;
    }
  };
  max-height: ${props => {
      var v;
      if ((props.row === "0" && props.column === "0") || props.transition) v = "100vh"
      else v = "0";

      return v;
    }
  };
  height: ${props => {
      var v;
      if ((props.row === "0" && props.column === "0") || props.transition) v = "100vh"
      else v = "0";

      return v;
    }
  };
  top: ${props => props.row * window.innerHeight + "px"};
  left: ${props => props.column * document.body.clientWidth + "px"};
  overflow: auto;
`;

export default class Page extends Component {
  constructor() {
    super();
    this.handleTransitionEnd = this.handleTransitionEnd.bind(this);
  }

  handleTransitionEnd() {
    this.props.handleTransition(this.props.active, false);
  }

  render() {
    return (
      <StyledPage {...this.props} onTransitionEnd={this.handleTransitionEnd}>
        {this.props.children}
      </StyledPage>
    )
  }
}
