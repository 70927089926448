import React, { Component } from 'react'
import Button from './Button.js'


export default class ButtonsPanel extends Component {
  constructor(props) {
    super(props);
    this.total = 4;
    this.marginTop = 30;
    this.marginRight = 20;

    this.state = {
      coords: this.updateButtons()
    };

    this.updateButtons = this.updateButtons.bind(this);
  }

  componentDidMount() {
    this.setState({
      coords: this.updateButtons()
    });

    window.addEventListener("resize", (event) => {
      this.setState({
        coords: this.updateButtons()
      });
    });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.layout != this.props.layout) {
      this.setState({
        coords: this.updateButtons()
      });
    }
  }

  updateButtons() {
    if (window.matchMedia("(max-width: 768px)").matches || 
        window.matchMedia("(max-height: 380px)").matches) {
      this.buttonWidth = 65;
      this.buttonHeight = 65;
      this.marginTop = this.buttonHeight / 4;
      this.marginRight = this.buttonWidth / 8;
      }
    else {
      this.buttonWidth = 100;
      this.buttonHeight = 100;
      this.marginTop = this.buttonHeight / 4;
      this.marginRight = this.buttonWidth / 5;
      }

    var spaceBetweenWidth = this.buttonWidth / 5;
    var spaceBetweenHeight = this.buttonHeight / 5;

    var coords = [];
    for (let i = 0; i < this.total; i++) {
      // Home
      if (this.props.layout == 0) {
        var gap = document.body.clientWidth / (this.total);
        coords.push({
          top: "30px",
          left: (i * gap) + (gap / 2) - (this.buttonWidth / 2) + "px"
        });
      }
      // Sections
      else if (this.props.layout == 1) {
        let left;
        left =  document.body.clientWidth - ((this.total * (this.buttonWidth + this.marginRight)) / 2) - this.marginRight;
        left = left + (i % 2 * (this.buttonWidth + spaceBetweenWidth)) + "px";
        coords.push({
          top: (this.marginTop + Math.floor(i / 2) * (this.buttonHeight + spaceBetweenHeight)) + "px",
          left: left
        });
      }
    }

    return coords;
  }

  render() {
    return (
      <>
        <Button name="Profile" key="0" width={this.buttonWidth} height={this.buttonHeight} coords={this.state.coords[0]} active={this.props.active} handleTransition={this.props.handleTransition}/>
        <Button name="Journey" key="1" width={this.buttonWidth} height={this.buttonHeight} coords={this.state.coords[1]} active={this.props.active} handleTransition={this.props.handleTransition}/>
        <Button name="Skills" key="2" width={this.buttonWidth} height={this.buttonHeight} coords={this.state.coords[2]} active={this.props.active} handleTransition={this.props.handleTransition}/>
        <Button name="Contact" key="3" width={this.buttonWidth} height={this.buttonHeight} coords={this.state.coords[3]} active={this.props.active} handleTransition={this.props.handleTransition}/>
      </>
    )
  }
}
