import styled from 'styled-components/macro'
import theme from './content/Theme.yaml'


export const StyledHeader = styled.h1`
  font-family: ${theme.fonts.headers};
  font-weight: bold;
  font-size: 2.5em;
  letter-spacing: 0.3em;
  margin-top: 60px;
  margin-bottom: 80px;
  margin-left: 5px;
`;

export const StyledSection = styled.div`
  position: relative;
  z-index: 100;
  padding: 100px 25px 100px 100px;

  @media(max-width: 1024px) {
    padding: 100px 25px 50px 50px;    
  }

  @media(max-width: 768px) {
    padding: 10px 5px 10px 5px;    
  }

  min-height: 100%;
`;
